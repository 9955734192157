import React from "react"
import { withPrefix, Link, useStaticQuery, graphql } from "gatsby"
// import parse from "html-react-parser"
// import Helmet from "react-helmet"

const Layout = ({ isHomePage, children }) => {
  const {
    wp: {
      generalSettings: { title, description },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    
    <div className="global-wrapper" data-is-root-path={isHomePage}>

      <header className="global-header">
        <div className="header-logo">
          <Link className="header-link-home" to="/">
            {description}
          </Link>
        </div>

        <div className="menu--primary">
          <nav>
            <ul>
              <li><Link to="/blog/" className="menu-link">Blog</Link></li>
              <li><Link to="/about/" className="menu-link">About</Link></li>
              {/* <li><Link to="/built-with/" className="menu-link">Built with</Link></li> */}
            </ul>
          </nav>
        </div>

      </header>

      <hr />

      <main>{children}</main>

      <hr />

      <footer>
        <div class="copyright">
          © {new Date().getFullYear()}, Built with TCB Technology
        </div>
        <div class="technology">
          <a href="/built-with">Built with</a>
        </div>
      </footer>
    </div>
  )
}

export default Layout
